<template>
  <div>
    <GlobalEvents
      @keyup.escape="clearSuggestedCompanyRegistrations"
      @click="handleClickOutside"
    />
    <UpsellBanner
      class="upsell-banner__margin"
      :description="upsellBannerDescription"
    />
    <div class="create-shop__label sk-text-large-semibold">
      {{ $t('create_shop.general_informations') }}
    </div>
    <div class="input-row__line-container">
      <div class="input-row--item">
        <SkInput
          ref="nameInput"
          v-model.trim="name"
          type="text"
          class="input-row--large"
          :label="$t('create_shop.shop_name_input')"
          :errored="!!nameErrorMessage"
          :error-message="nameErrorMessage"
          :debounce="handleNameChanges"
          :debounce-ms="300"
        />
        <div>
          <SkDropdown
            ref="companyNameDropdown"
            placement="bottom-start"
            y-offset="10px"
            :trigger="null"
          >
            <!-- eslint disabled because we need to have 2 menus in the same page -->
            <!-- but html ids are unique -->
            <!-- eslint-disable-next-line vue/v-slot-style -->
            <template v-slot:menu>
              <SuggestedCompanyRegistrations
                v-if="showCompanyNameDropdown"
                :suggested-companies="suggestedCompanyRegistrations"
                :search-input="name"
                :related-input="$refs.nameInput"
                @select-suggested-company="autofillBillingInfos"
              />
            </template>
          </SkDropdown>
        </div>
      </div>
      <div class="input-row--item">
        <SkInput
          ref="registrationNumberInput"
          v-model.trim="registrationNumber"
          class="input-row--large"
          type="text"
          :label="$t('fill_billing_info.billing_line.shop_registration_number_input')"
          :errored="!!registrationNumberErrorMessage"
          :error-message="registrationNumberErrorMessage"
          :debounce="handleAndValidateRegistrationNumber"
          :debounce-ms="300"
        />
        <SkDropdown
          ref="registrationNumberDropdown"
          placement="bottom-start"
          y-offset="10px"
          :trigger="null"
        >
          <!-- eslint disabled because we need to have 2 menus in the same page -->
          <!-- but html ids are unique -->
          <!-- eslint-disable-next-line vue/v-slot-style -->
          <template v-slot:menu>
            <SuggestedCompanyRegistrations
              v-if="showRegistrationNumberDropdown"
              :suggested-companies="suggestedCompanyRegistrations"
              :search-input="registrationNumber"
              :related-input="$refs.registrationNumberInput"
              @select-suggested-company="autofillBillingInfos"
            />
          </template>
        </SkDropdown>
      </div>
    </div>
    <div class="create-shop__body__input-row">
      <div class="billing-address__label sk-text-large-semibold">
        {{ billingAddressLabel }}
      </div>
      <div class="input-row__line-container">
        <SkInput
          v-model.trim="address"
          class="input-row__second-line"
          type="text"
          :label="$t('fill_billing_info.billing_line.address_input')"
          :errored="!!addressErrorMessage"
          :error-message="addressErrorMessage"
          :debounce="validateMandatoryField('address')"
          :debounce-ms="100"
        />
      </div>
      <div class="input-row__line-container">
        <SkInput
          v-model.trim="city"
          class="input-row--item"
          type="text"
          :label="$t('fill_billing_info.billing_line.city_input')"
          :errored="!!cityErrorMessage"
          :error-message="cityErrorMessage"
          :debounce="validateMandatoryField('city')"
          :debounce-ms="100"
        />
        <SkInput
          v-model.trim="zipcode"
          class="input-row--item"
          type="text"
          :label="$t('fill_billing_info.billing_line.zipcode_input')"
          :errored="!!zipcodeErrorMessage"
          :error-message="zipcodeErrorMessage"
          :debounce="validateMandatoryField('zipcode')"
          :debounce-ms="100"
        />
        <SkSelectV2
          v-model.trim="country"
          class="input-row--item"
          :options="countryOptions"
          :label="$t('fill_billing_info.billing_line.country_input')"
          :errored="!!countryErrorMessage"
          :error-message="countryErrorMessage"
          :debounce="validateMandatoryField('country')"
          :debounce-ms="100"
          :disabled="!isAllowToUpdateCountryField"
        />
      </div>
    </div>
    <div v-if="!isBillingOnOrganisation">
      <div class="create-shop__label">
        <div class="sk-text-large-semibold">
          {{ $t('create_shop.payment_informations') }}
        </div>
        <div>
          <SkOroraTag
            v-if="hasAddedPayment && !isOnlyIbanPayment"
            variant="green"
          >
            {{ $t('create_shop.payment_added_tag') }}
          </SkOroraTag>
        </div>
      </div>
      <div>
        <div class="create-shop__payment">
          <SkOroraButton
            v-if="!isOnlyIbanPayment"
            class="create-shop__payment-button"
            variant="secondary"
            @click="openCheckout"
          >
            {{ paymentButtonLabel }}
          </SkOroraButton>

          <div
            v-else
            class="input-row__line-container"
          >
            <SkInput
              v-model.trim="ibanWithSpacesAllowed"
              type="text"
              :label="$t('fill_billing_info.billing_line.iban_input')"
              :errored="!!ibanErrorMessage"
              :error-message="ibanErrorMessage"
              :debounce="validateIban"
              :debounce-ms="100"
            />
            <SkInput
              v-model.trim="email"
              type="text"
              :label="$t('fill_billing_info.billing_line.email_input')"
              :errored="!!emailErrorMessage"
              :error-message="emailErrorMessage"
              :debounce="validateEmail"
              :debounce-ms="100"
            />
          </div>
        </div>

        <div class="create-shop__payment-label sk-text-medium-semibold">
          <ShieldWithCheckIcon
            :fill="$skColors.skBlue50"
            width="16"
            height="16"
          />
          <span>{{ $t('create_shop.secure_payment_label') }}</span>
        </div>

        <div>
          <p class="sk-text-x-small-regular">
            {{ $t('create_shop.disclamer') }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="sk-text-x-small-regular"
          >
            <i18n
              path="create_shop.terms_of_service"
            >
              <template #linkTerms>
                <SkOroraLink
                  target="_blank"
                  rel="noopener noreferrer"
                  size="x-small"
                  :href="$t('create_shop.link_terms_of_service')"
                >
                  {{ $t('create_shop.text_terms_of_service') }}
                </SkOroraLink>
              </template>
              <template #linkPrivacy>
                <SkOroraLink
                  target="_blank"
                  rel="noopener noreferrer"
                  size="x-small"
                  :href="$t('create_shop.link_privacy_policy')"
                >
                  {{ $t('create_shop.text_privacy_policy') }}
                </SkOroraLink>
              </template>
            </i18n>
          </p>
        <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import GlobalEvents from 'vue-global-events';

import UpsellBanner from '@components/UpsellBanner';
import SuggestedCompanyRegistrations from '@components/BillingLineContent/SuggestedCompanyRegistrations';
import dayjs from 'dayjs';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import {
  removeWhiteSpaces, extractPrice,
} from '@/shared/utils/method_helper';
import {
  isValidSiret,
  isValidIban,
  isValidEmail,
} from '@/shared/utils/validators';
import { VUE_APP_CHARGEBEE_SITE } from '@/shared/config';
import { getNaf } from '@/shared/utils/naf';
import { COUNTRY_KEYS } from '@/shared/constants/country-keys';

export default {
  name: 'CreateShop',
  components: { GlobalEvents, UpsellBanner, SuggestedCompanyRegistrations },
  data() {
    return {
      registrationNumberWithSpacesAllowed: null,
      addressErrorMessage: null,
      cityErrorMessage: null,
      zipcodeErrorMessage: null,
      countryErrorMessage: null,
      ibanErrorMessage: null,
      emailErrorMessage: null,
      loading: false,
      suggestedCompanyRegistrations: [],
      nameErrorMessage: null,
      registrationNumberErrorMessage: null,
      isDropDownOpen: {
        companyName: false,
        registrationNumber: false,
      },
      cbInstance: window.Chargebee.init({ site: VUE_APP_CHARGEBEE_SITE }),
      hasAddedPayment: false,
    };
  },
  computed: {
    ...mapGetters(['isBillingOnOrganisation', 'isDevFeatureFlagActive']),
    ...mapState(['shopsPaymentInfos', 'quote']),

    isAllowToUpdateCountryField() {
      return this.isDevFeatureFlagActive('FEATUREDEV_INAPP_ROLLOUT_RAUL_COUNTRY');
    },
    countryOptions() {
      return COUNTRY_KEYS.map(country => ({ id: country, text: this.$t(`common.countries.${country}`) }));
    },
    newPackDate() {
      return dayjs(this.quote.contract_terms.contract_term_start).locale(this.$i18n.locale).format('DD MMMM YYYY');
    },
    addedPrice() {
      return extractPrice(this.quote, this.quote.totals.total_amount);
    },
    packName() {
      return this.quote.quote_line_items.find(elem => elem.title.includes('Pack')).title;
    },
    listOption() {
      return this.quote.quote_line_items.filter(elem => elem.type.includes('Option')).map(elem => elem.title);
    },
    lastOption() {
      return this.listOption[this.listOption.length - 1];
    },
    translateListOptionWithoutLastElement() {
      if (this.listOption.length === 1) {
        return this.listOption[0];
      }
      return this.listOption.slice(0, this.listOption.length - 1).join(', ');
    },
    upsellBannerTranslateParam() {
      return {
        start_date: this.newPackDate,
        pack_name: this.packName,
        options: this.translateListOptionWithoutLastElement,
        price: this.addedPrice,
        last_option: this.lastOption,
      };
    },
    upsellBannerDescription() {
      const key = this.quote.type_of_payment === 'Shop' ? 'shop' : 'organisation';
      const engagmentUnit = this.quote.contract_terms.total_engagement_unit;

      return this.$tc(`upsell_banner_${engagmentUnit}.${key}_payment_description`, this.listOption.length, this.upsellBannerTranslateParam);
    },
    isSubmitDisabled() {
      const isPaymentValid = this.hasAddedPayment ||
        (this.isOnlyIbanPayment && !!this.iban && !!this.email);
      const isFilled =
        this.address && this.city && this.zipcode && this.country &&
        this.registrationNumber && this.name;
      const hasAnyErrorMessage = !!this.addressErrorMessage || !!this.nameErrorMessage ||
        !!this.cityErrorMessage || !!this.zipcodeErrorMessage || !!this.countryErrorMessage ||
        !!this.registrationNumberErrorMessage || !!this.ibanErrorMessage ||
        !!this.emailErrorMessage;

      return hasAnyErrorMessage || !isFilled || !isPaymentValid;
    },
    // Only INSEE API is implemented to look for company registrations for the moment.
    isSupportedCountryForRegistrationLookup() {
      return this.paymentInfos.billingAddress.country_code === 'FR';
    },
    name: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.paymentInfos.name;
      },
      set(value) {
        this.validatePresence('name');
        this.updatePaymentInfosAttribute({
          attribute: 'name',
          value,
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    address: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.paymentInfos.billingAddress.line1;
      },
      set(value) {
        this.validatePresence('line1');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.paymentInfos.billingAddress, line1: value },
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    city: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.paymentInfos.billingAddress.city;
      },
      set(value) {
        this.validatePresence('city');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.paymentInfos.billingAddress, city: value },
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    country: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.paymentInfos.billingAddress.country_code;
      },
      set(value) {
        this.validatePresence('country');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: {
            ...this.paymentInfos.billingAddress,
            country_code: value,
            country: this.$t(`common.countries.${value}`, 'en'), // Raul expects an english country's value
          },
          index: this.paymentInfos.salesforceId,
        });

        // since the registration number is depending on the country, we have to run again the validation
        this.validateRegistrationNumber();
      },
    },
    zipcode: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.paymentInfos.billingAddress.zip;
      },
      set(value) {
        this.validatePresence('zip');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.paymentInfos.billingAddress, zip: value },
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    ibanWithSpacesAllowed: {
      get() {
        return this.paymentInfos.iban || null;
      },
      set(value) {
        this.updatePaymentInfosAttribute({
          attribute: 'iban',
          value,
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    email: {
      get() {
        return this.paymentInfos.billingAddress.email || null;
      },
      set(value) {
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.paymentInfos.billingAddress, email: value },
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    registrationNumber: {
      get() {
        return this.registrationNumberWithSpacesAllowed &&
          removeWhiteSpaces(this.registrationNumberWithSpacesAllowed);
      },
      set(value) {
        this.validateRegistrationNumber();
        this.registrationNumberWithSpacesAllowed = value;
        this.updatePaymentInfosAttribute({
          attribute: 'registrationNumber',
          value: this.registrationNumber,
          index: this.paymentInfos.salesforceId,
        });
      },
    },
    paymentInfos() {
      // On contract change we only have one shop so only one payment info
      return this.shopsPaymentInfos[0];
    },
    showCompanyNameDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.companyName;
    },
    showRegistrationNumberDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.registrationNumber;
    },
    billingAddressLabel() {
      return this.isBillingOnOrganisation ?
        this.$t('create_shop.billing_address_label.billing_on_orga') :
        this.$t('create_shop.billing_address_label.billing_on_shop');
    },
    chargebeeId() {
      return this.paymentInfos.paymentOwnerChargebeeId;
    },
    paymentButtonLabel() {
      return this.hasAddedPayment ? this.$t('create_shop.payment_button.modify_payment') : this.$t('create_shop.payment_button.add_payment');
    },
    isOnlyIbanPayment() {
      return this.quote.payment_method === 'Direct debit';
    },
    iban() {
      return this.ibanWithSpacesAllowed && removeWhiteSpaces(this.ibanWithSpacesAllowed);
    },
  },
  watch: {
    isSubmitDisabled(newValue) {
      if (newValue) {
        this.$emit('disable-submit');
      } else {
        this.$emit('enable-submit');
      }
    },
  },
  mounted() {
    this.$emit('disable-submit');
    this.registrationNumber = this.paymentInfos.registrationNumber || null;
    this.address = this.paymentInfos.billingAddress?.line1 || null;
    this.city = this.paymentInfos.billingAddress?.city || null;
    this.zipcode = this.paymentInfos.billingAddress?.zip || null;
    this.country = this.paymentInfos.billingAddress?.country_code || null;

    this.hasAddedPayment = this.paymentInfos.hasValidPaymentMethod ||
      this.isBillingOnOrganisation;
  },
  methods: {
    ...mapActions(['updatePaymentInfosAttribute']),

    validateMandatoryField(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t('fill_billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    // for the companyName "L'AMI DU PAIN" with 'AMI D' as input, we return the array:
    // [{ text: "L'" },
    //  { text: 'AMI D', isHighlighted: true },
    //  { text: 'U PAIN' }]
    //
    highlightMatch(result, searchedText) {
      const matching = result.toLowerCase().match(searchedText.toLowerCase());
      if (!matching) return [{ text: result }];

      const formattedText = [
        { text: searchedText, isHighlighted: true },
        { text: result.substring(matching.index + searchedText.length) },
      ];

      if (matching.index > 0) {
        formattedText.unshift({ text: result.substring(0, matching.index) });
      }

      return formattedText;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown?.$el?.contains(event.target)) return;

      this.clearSuggestedCompanyRegistrations();
    },
    clearSuggestedCompanyRegistrations() {
      this.suggestedCompanyRegistrations = [];
      this.isDropDownOpen.registrationNumber = false;
      this.isDropDownOpen.companyName = false;
      this.$refs.companyNameDropdown.hide();
      this.$refs.registrationNumberDropdown.hide();
    },
    handleNameChanges() {
      this.validatePresence('name');
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.name.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByName()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, this.name);
          this.isDropDownOpen.companyName = true;
          this.isDropDownOpen.registrationNumber = false;
          this.$refs.companyNameDropdown.open();
          this.$refs.registrationNumberDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    populateSuggestedCompanyRegistrations(suggestedCompanies, searchedText) {
      this.suggestedCompanyRegistrations = suggestedCompanies.map(suggestedCompany => ({
        address: suggestedCompany.address,
        city: suggestedCompany.city,
        creationDate: suggestedCompany.creationDate,
        shopRegistrationNumber: suggestedCompany.shopRegistrationNumber,
        displayLabel: this.highlightMatch(
          suggestedCompany.name,
          searchedText,
        ),
        // We need to remove the dot in the main activity to be able to use the naf filter
        // Dots are added by the INSEE API
        mainActivity: getNaf(suggestedCompany.mainActivity.replaceAll('.', '')),
        name: suggestedCompany.name,
        organisationRegistrationNumber: suggestedCompany.organisationRegistrationNumber,
        zipCode: suggestedCompany.zipCode,
      }));
    },
    handleAndValidateRegistrationNumber() {
      this.handleRegistrationNumberInput();
      this.validateRegistrationNumber();
    },
    handleRegistrationNumberInput() {
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.registrationNumber.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByRegistrationNumber()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, '');
          this.isDropDownOpen.registrationNumber = true;
          this.isDropDownOpen.companyName = false;
          this.$refs.registrationNumberDropdown.open();
          this.$refs.companyNameDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    validateRegistrationNumber() {
      this.validatePresence('registrationNumber');
      if (!this.registrationNumber || !this.isSupportedCountryForRegistrationLookup) return;

      if (!isValidSiret(this.registrationNumber)) {
        this.registrationNumberErrorMessage = this.$t('fill_billing_info.billing_line.siret_invalid');
      }
    },
    validatePresence(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t('fill_billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    lookupCompanyRegistrationsByName() {
      return billingAutomationClient
        .getCompanyRegistrationsByName(this.name);
    },
    lookupCompanyRegistrationsByRegistrationNumber() {
      return billingAutomationClient
        .getCompanyRegistrationsByShopRegistrationNumber(this.registrationNumber);
    },
    openCheckout() {
      window.addEventListener('message', this.handleMessageReceived);
      // 'this' unavailable inside openCheckout function
      const vueComponent = this;
      try {
        this.cbInstance.openCheckout({
          hostedPage() {
            return billingAutomationClient
              .createCheckoutByChargebeeIdAndBillingEntity(vueComponent.chargebeeId);
          },
          close() {
            window.removeEventListener('message', vueComponent.handleMessageReceived);
          },
          error(error) {
            vueComponent.showCheckoutOpenErrorToast();
          },
        });
      } catch (error) {
        this.cbInstance.closeAll();
        this.showCheckoutOpenErrorToast();
      }
    },
    showCheckoutOpenErrorToast() {
      this.makeAlertToast(this.$t('fill_billing_info.billing_line.checkout_open_error'));
    },
    handleMessageReceived(evt) {
      // evt.data is either Object, or a simple name string
      if (evt?.data?.key !== 'cb.payment_source.add') return;

      if (evt?.data?.status === 'error') {
        this.hasAddedPayment = false;
        this.showBillingErrorToast();
      } else {
        this.hasAddedPayment = true;
      }
    },
    showBillingErrorToast() {
      this.makeAlertToast(this.$t('fill_billing_info.billing_line.billing_error'));
    },
    validateIban() {
      this.validateMandatoryField('iban');
      if (this.iban && !isValidIban(this.iban)) {
        this.ibanErrorMessage = this.$t('fill_billing_info.billing_line.iban_invalid');
      }
    },
    autofillBillingInfos(company) {
      this.name = company.name;
      this.registrationNumber = company.shopRegistrationNumber;
      this.address = company.address;
      this.city = company.city;
      this.zipcode = company.zipCode;
      this.clearSuggestedCompanyRegistrations();
    },
    validateEmail() {
      this.validateMandatoryField('email');
      if (this.email && !isValidEmail(this.email)) {
        this.emailErrorMessage = this.$t('fill_billing_info.billing_line.email_invalid');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-shop__label {
  display: flex;
  margin: 24px 0 16px;
  line-height: 17px;
  gap: 12px;
}

.upsell-banner__margin {
  margin-top: 24px;
}

.input-row__line-container {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.create-shop__payment-label {
  display: flex;
  gap: 4px;
  color: $sk-blue-50;
  margin-bottom: 24px;
  align-items: center;
}

.input-row--item {
  flex-grow: 1;
}

.input-row__second-line {
  max-width: 100%;
}

.create-shop__body__input-row {
  margin: 16px 0;

  .billing-address__label {
    margin: 32px 0 16px 0;
  }
}

.create-shop__payment {
  margin-bottom: 16px;
}
</style>
