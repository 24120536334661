import Vue from 'vue';
import Vuex from 'vuex';

import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import FeatureFlagClient from '@/shared/utils/clients/featureFlagClient';
import { isCustomerPaymentInfoCompleted } from '@/shared/utils/method_helper';

const featureFlagClient = new FeatureFlagClient();
Vue.use(Vuex);

const initialState = {
  devFeatureFlags: [],
  isScrollingThroughCompanyRegistrations: false,
  password: '',
  provisioningAction: null,
  quote: {},
  shopsPaymentInfos: [],
  organisationPaymentInfos: {},
};

const mutations = {
  populatePaymentInfos(state) {
    const organisation = state.quote.organisation;

    state.organisationPaymentInfos = {
      billingAddress: organisation.billing_address,
      customerChargebeeId: organisation.CB_customer_id,
      paymentOwnerChargebeeId: organisation.CB_payment_owner_id,
      hasValidPaymentMethod: organisation.has_valid_payment_method,
      salesforceId: organisation.id,
      name: organisation.name,
      registrationNumber: organisation.registration_number,
      iban: '',
      isCustomerPaymentInfoCompleted: isCustomerPaymentInfoCompleted(organisation),
    };

    // In the contract change context, Raul quote SHOULD only contain
    // the shop we are creating
    if (organisation.shops.length !== 1 && this.getters.isContractChange) {
      throw new Error('invalid quote');
    }

    state.shopsPaymentInfos = organisation.shops.map(shop => ({
      billingAddress: shop.billing_address,
      customerChargebeeId: shop.CB_customer_id,
      paymentOwnerChargebeeId: shop.CB_payment_owner_id,
      hasValidPaymentMethod: shop.has_valid_payment_method,
      salesforceId: shop.id,
      name: shop.name,
      registrationNumber: shop.registration_number,
      iban: '',
      isCustomerPaymentInfoCompleted: isCustomerPaymentInfoCompleted(shop),
    }));
  },
  setPassword(state, password) {
    state.password = password;
  },
  setQuote(state, quote) {
    state.quote = quote;
  },
  setDevFeatureFlags(state, devFeatureFlags) {
    state.devFeatureFlags = devFeatureFlags;
  },
  setProvisioningAction(state, provisioningAction) {
    state.provisioningAction = provisioningAction;
  },
  setPaymentType(state, paymentType) {
    state.quote.type_of_payment = paymentType;
  },
  updateShopPaymentInfosAttribute(state, payload) {
    const { attribute, index, value } = payload;
    state.shopsPaymentInfos[index][attribute] = value;
  },
  setIsScrollingThroughCompanyRegistrations(state, isScrollingThroughCompanyRegistrations) {
    state.isScrollingThroughCompanyRegistrations = isScrollingThroughCompanyRegistrations;
  },
  updateOrganisationPaymentInfosAttribute(state, payload) {
    const { attribute, value } = payload;
    state.organisationPaymentInfos[attribute] = value;
  },
  updateQuoteOrganisation(state, newOrganisation) {
    state.quote.organisation = newOrganisation;
  },
  updateQuoteShop(state, payload) {
    const { index, newShop } = payload;
    state.quote.organisation.shops[index] = newShop;
  },
};

const actions = {
  fetchQuote({ commit }, quoteId) {
    return billingAutomationClient.getQuoteById(quoteId)
      .then(quote => { commit('setQuote', quote.payload); });
  },
  validateQuote({ commit }) {
    const quote = this.state.quote;
    return billingAutomationClient.validateQuote(quote.id, quote)
      .then(updatedQuote => { commit('setQuote', updatedQuote.payload); });
  },
  updateQuoteBillingInfosGenericAction({ commit, state }) {
    return billingAutomationClient.updateQuoteBillingInfos(state.quote.id, state.quote)
      .then(updatedQuote => { commit('setQuote', updatedQuote.payload); });
  },
  async updateQuoteBillingInfos({ dispatch, getters, state }) {
    // When the users pays at organisation level, we want to have only one button on the fillBillingInfos page
    // This button will update the billing infos on Salesforce no matter the type of payment
    // But in orga level, we want first to add the payment method on Chargebee
    // Then update the billing infos on Salesforce

    // But if we display the payment by card, we don't need to create a source on Chargebee
    // As it's done in chargebee modal, we just need to update the billing infos on Salesforce
    if (!getters.displayPaymentByCreditCard && getters.isBillingOnOrganisation) {
      return dispatch('createChargebeeSource', state.organisationPaymentInfos)
        .then(() => dispatch('updateQuoteBillingInfosGenericAction'));
    }

    if (getters.isBillingOnOrganisation) {
      await dispatch('updatePaymentInfosInQuote', state.organisationPaymentInfos);
    }

    return dispatch('updateQuoteBillingInfosGenericAction');
  },
  updateQuoteShopBillingInfos({ commit, state }) {
    return billingAutomationClient.updateQuoteShopBillingInfos(state.quote.id, state.quote)
      .then(updatedQuote => { commit('setQuote', updatedQuote.payload); });
  },
  createChargebeeSource({ dispatch }, paymentInfos) {
    return billingAutomationClient
      .createChargebeeSource(
        paymentInfos.paymentOwnerChargebeeId,
        paymentInfos.billingAddress.city,
        paymentInfos.billingAddress.country_code,
        paymentInfos.billingAddress.email,
        paymentInfos.billingAddress.first_name,
        paymentInfos.iban,
        paymentInfos.billingAddress.last_name,
        paymentInfos.billingAddress.line1,
        paymentInfos.billingAddress.zip,
      ).then(async () => {
        const updatePaymentInfosAttribute = {
          attribute: 'hasValidPaymentMethod',
          value: true,
          index: paymentInfos.salesforceId,
        };

        dispatch('updatePaymentInfosAttribute', updatePaymentInfosAttribute);

        await dispatch('updatePaymentInfosInQuote', paymentInfos).catch(error => {
          // If updatePaymentInfosInQuote fails, we need to revert the changes
          dispatch('updatePaymentInfosAttribute', {
            ...updatePaymentInfosAttribute,
            value: false,
          });
          throw error;
        });
      });
  },
  async completeCustomerPaymentInfos({ dispatch }, paymentInfos) {
    const updatePaymentInfosAttribute = {
      attribute: 'isCustomerPaymentInfoCompleted',
      value: true,
      index: paymentInfos.salesforceId,
    };

    dispatch('updatePaymentInfosAttribute', updatePaymentInfosAttribute);

    await dispatch('updatePaymentInfosInQuote', paymentInfos).catch(error => {
      // If updatePaymentInfosInQuote fails, we need to revert the changes
      dispatch('updatePaymentInfosAttribute', {
        ...updatePaymentInfosAttribute,
        value: false,
      });
      throw error;
    });
  },
  async fetchFeatureFlags({ commit }) {
    const { data } = await featureFlagClient.fetchFeatures();
    commit('setDevFeatureFlags', data.feature_flags_dev);
  },
  updateChargebeePaymentOwnerIds({ commit, getters, state }) {
    // When the user changes the payment type, we need to update the paymentOwnerChargebeeId for each resource
    // These ids are used in Salesforce to determine which payment owner to use when creating the Chargebee subscription
    if (getters.isBillingOnOrganisation) {
      commit('updateOrganisationPaymentInfosAttribute', {
        attribute: 'paymentOwnerChargebeeId',
        value: state.organisationPaymentInfos.customerChargebeeId,
      });

      state.shopsPaymentInfos.forEach((_shopPaymentInfos, index) => {
        commit('updateShopPaymentInfosAttribute', {
          attribute: 'paymentOwnerChargebeeId',
          value: state.organisationPaymentInfos.customerChargebeeId,
          index,
        });
      });
    } else {
      commit('updateOrganisationPaymentInfosAttribute', { attribute: 'paymentOwnerChargebeeId', value: null });

      state.shopsPaymentInfos.forEach((shopPaymentInfos, index) => {
        commit('updateShopPaymentInfosAttribute', {
          attribute: 'paymentOwnerChargebeeId',
          value: shopPaymentInfos.customerChargebeeId,
          index,
        });
      });
    }
  },
  updatePaymentInfosAttribute({ commit, getters, state }, params) {
    if (getters.isBillingOnOrganisation && !getters.isContractChange) {
      commit('updateOrganisationPaymentInfosAttribute', params);
    } else {
      const idToUpdate = state.shopsPaymentInfos.findIndex(
        shop => shop.salesforceId === params.index,
      );
      commit('updateShopPaymentInfosAttribute', { ...params, index: idToUpdate });
    }
  },
  async updatePaymentInfosInQuote({ commit, dispatch, getters, state }, paymentInfos) {
    const updatedInfos = {
      billing_address: paymentInfos.billingAddress,
      CB_payment_owner_id: paymentInfos.paymentOwnerChargebeeId,
      has_valid_payment_method: paymentInfos.hasValidPaymentMethod,
      name: paymentInfos.name,
      registration_number: paymentInfos.registrationNumber,
    };

    if (getters.isBillingOnOrganisation && !getters.isContractChange) {
      commit('updateQuoteOrganisation', {
        ...state.quote.organisation,
        ...updatedInfos,
      });

      state.quote.organisation.shops.forEach((shop, index) => {
        commit('updateQuoteShop', {
          index,
          newShop: {
            ...shop,
            CB_payment_owner_id: paymentInfos.paymentOwnerChargebeeId,
          },
        });
      });
    } else {
      const quoteShopIndex = state.quote.organisation.shops.findIndex(
        shop => shop.id === paymentInfos.salesforceId,
      );

      const updatedShop = {
        ...state.quote.organisation.shops[quoteShopIndex],
        ...updatedInfos,
      };

      commit('updateQuoteShop', {
        index: quoteShopIndex,
        newShop: updatedShop,
      });

      commit('updateQuoteOrganisation', {
        ...state.quote.organisation,
        CB_payment_owner_id: null,
      });

      await dispatch('updateQuoteShopBillingInfos')
        .catch(error => {
          // If updateQuoteShopBillingInfos fails, we need to revert the changes
          commit('updateQuoteShop', {
            index: quoteShopIndex,
            newShop: {
              ...updatedShop,
              has_valid_payment_method: false,
            },
          });
          throw error;
        });
    }
  },
  setPaymentType({ commit, dispatch }, paymentType) {
    commit('setPaymentType', paymentType);
    dispatch('updateChargebeePaymentOwnerIds');
  },
};

function isFeatureFlagActive(featureFlags, flagName) {
  const featureFlag = featureFlags.find(
    flag => flag.name === flagName,
  );
  if (!featureFlag) return false;
  return !!featureFlag.active;
}

const getters = {
  isScrollingThroughCompanyRegistrations: state => state.isScrollingThroughCompanyRegistrations,
  isDevFeatureFlagActive: state => flagName => isFeatureFlagActive(
    state.devFeatureFlags, flagName,
  ),
  isContractChange: state => !!state.quote.organisation &&
    !!state.quote.organisation.SK_organisation_id,
  isBillingOnOrganisation: state => state.quote.type_of_payment === 'Organization',
  validatedShopPaymentInfos: state => state.shopsPaymentInfos.filter(
    shopPaymentInfo => !!shopPaymentInfo.hasValidPaymentMethod,
  ),
  displayPaymentByCreditCard: state => state.quote.payment_method === 'Credit card',
};

export default new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  getters,
});
