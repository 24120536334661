<template>
  <div class="fill-billing-info__wrapper">
    <div class="fill-billing-info__payment-radio__container">
      <div class="fill-billing-info__payment-radio__title">
        {{ $t('fill_billing_info.payment_radio.title') }}
      </div>
      <div class="fill-billing-info__payment-radio__row">
        <SkRadio
          v-for="paymentOption in paymentOptions"
          :key="paymentOption.id"
          v-model="selectedPaymentOption"
          class="fill-billing-info__payment-radio__row__option"
          :data-value="paymentOption.id"
          @input="handlePaymentOptionChange"
        >
          {{ paymentOption.text }}
        </SkRadio>
      </div>
    </div>
    <div
      v-if="isBillingPerShops"
      class="fill-billing-info__billing-lines__wrapper"
    >
      <ShopBillingLine
        v-for="(shopPaymentInfos, index) in shopsPaymentInfos"
        ref="shopLines"
        :key="index"
        :shop-payment-infos="shopPaymentInfos"
        @validate-shop-payment-infos="validateShopPaymentInfos"
      />
    </div>
    <div
      v-else
      class="fill-billing-info__billing-lines__wrapper"
    >
      <OrganisationBillingLine v-on="$listeners" />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
import ShopBillingLine from '@components/ShopBillingLine';
import OrganisationBillingLine from '@components/OrganisationBillingLine';
import { SkRadio } from '@skelloapp/skello-ui';

export default {
  name: 'FillBillingInfo',
  components: {
    OrganisationBillingLine,
    ShopBillingLine,
    SkRadio,
  },
  data() {
    return {
      paymentOptions: [
        {
          id: 'shop', text: this.$t('fill_billing_info.payment_radio.shop'),
        },
        {
          id: 'organisation', text: this.$t('fill_billing_info.payment_radio.organisation'),
        },
      ],
      selectedPaymentOption: 'shop',
    };
  },
  computed: {
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation']),
    ...mapState(['shopsPaymentInfos']),

    isBillingPerShops() {
      return this.selectedPaymentOption === 'shop';
    },
    isShopLineCompletedMethod() {
      return this.displayPaymentByCreditCard ?
        shop => shop.isCustomerPaymentInfoCompleted :
        shop => shop.hasValidPaymentMethod;
    },
  },
  mounted() {
    this.populatePaymentInfos();
    this.selectedPaymentOption = this.isBillingOnOrganisation ? 'organisation' : 'shop';
    this.$emit('disable-submit');
    // We need to use setTimeout to allow vue to load the shop refs
    // so that they can be used in openNextLine
    if (this.isBillingPerShops) {
      setTimeout(() => this.openNextLine(), 1);
    }
  },
  methods: {
    ...mapActions(['setPaymentType']),
    ...mapMutations(['populatePaymentInfos']),

    validateShopPaymentInfos() {
      if (this.shopsPaymentInfos.every(this.isShopLineCompletedMethod)) {
        this.$emit('enable-submit');
      } else {
        this.openNextLine();
      }
    },
    handlePaymentOptionChange(newValue) {
      if (newValue === 'organisation') {
        this.setPaymentType('Organization');
      } else {
        this.setPaymentType('Shop');
        // We also need to use setTimeout to allow vue to load the shop refs
        // so that they can be used in openNextLine
        setTimeout(() => this.openNextLine(), 1);
      }
      this.$emit('disable-submit');
    },
    openNextLine() {
      const index = this.shopsPaymentInfos.findIndex(
        shop => !this.isShopLineCompletedMethod(shop),
      );
      if (index === -1 || !this.$refs.shopLines) return;

      this.$refs.shopLines[index].open();
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-billing-info__payment-radio {
  &__container {
    margin: 24px 0px;
    padding: 16px;
    border: 1px solid $sk-grey-10;
    border-radius: 8px;
  }
  &__title {
    font-size: $fs-text-l;
    font-weight: $fw-semi-bold;
    line-height: 17px;
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    &__option:last-of-type {
      margin-left: 20px;
    }
  }
}
</style>
