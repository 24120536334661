<template>
  <div>
    <ContractChangeForm
      :current-step="currentStep"
      :step-loading="isStepLoading"
      :is-submit-disabled="isSubmitDisabled"
      @submit="submitStep"
    >
      <RouterView
        @disable-submit="handleDisableSubmit"
        @enable-submit="handleEnableSubmit"
      />
    </ContractChangeForm>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import ContractChangeForm from '@components/ContractChangeForm';
import WebsocketClient from '@/shared/utils/clients/websocketClient';
import { VUE_APP_SKELLO_API_URL } from '@/shared/config';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import {
  organisationIsBillable,
  isBillable,
  isCustomerPaymentInfoCompleted,
} from '@/shared/utils/method_helper';

export default {
  name: 'ContractChange',
  components: { ContractChangeForm },
  data() {
    return {
      isStepLoading: false,
      isSubmitDisabled: false,
      websocketClient: null,
    };
  },
  computed: {
    ...mapState(['quote', 'password', 'shopsPaymentInfos']),
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation']),

    billingInfoCompletedForCreditCardPayment() {
      if (this.isBillingOnOrganisation) {
        return isCustomerPaymentInfoCompleted(this.quote.organisation);
      }

      return this.quote.organisation.shops.every(isCustomerPaymentInfoCompleted);
    },
    billingInfoCompleted() {
      if (!this.quote.organisation) return false;

      const organisation = this.quote.organisation;

      if (this.displayPaymentByCreditCard) {
        return this.billingInfoCompletedForCreditCardPayment;
      }

      const hasShopAwaitingPayment =
        organisation.shops
          .filter(isBillable)
          .find(shop => !shop.has_valid_payment_method);
      // If Organisation not billable, then we don't check if has_valid_payment_method
      const hasOrganisationAwaitingPayment =
        organisationIsBillable(this.quote.organisation) && !organisation.has_valid_payment_method;
      return !hasShopAwaitingPayment && !hasOrganisationAwaitingPayment;
    },
    isQuoteInvalid() {
      return !this.quote ||
        !this.quote.organisation ||
        this.isQuoteExpired ||
        !['Sent', 'Presented', 'Accepted'].includes(this.quote.status);
    },
    isQuoteExpired() {
      const expirationDate = new Date(this.quote.valid_till);
      if (!expirationDate) return false;

      // JS counts in milliseconds, other programs in seconds
      // In order to avoid float bugs we convert up, not down
      return new Date() > expirationDate;
    },
    currentStep() {
      return this.contractChangeSteps.find(step => step.routerName === this.$route.name);
    },
    totalStepCount() {
      // if billingInfoCompleted is true in case of contract change, the flow contains one less step
      if (this.billingInfoCompleted) return this.contractChangeSteps.length - 3;

      // no need to count the error and loadingProvisioning step
      return this.contractChangeSteps.length - 2;
    },
    // Needs to be a computed to reload on i18n lang change
    contractChangeSteps() {
      return [
        {
          title: this.$t('create_shop.title'),
          routerName: 'createShop',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepIndex: 1,
          submitAction: this.submitProvisioning,
          submitLabel: this.$t('validate_quote.submit_label'),
        },
        {
          title: this.$t('step_error.title'),
          routerName: 'contractChangeError',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepCountHidden: true,
          submitAction: async () => { },
          submitHidden: true,
        },
        {
          title: this.$t('create_shop.create_shop_loader.title'),
          routerName: 'createShopLoader',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepCountHidden: true,
          submitAction: async () => { },
          submitLabel: this.$t('validate_quote.submit_label'),
        },
      ];
    },
    quoteId() {
      return this.$route.query.id;
    },
  },
  created() {
    if (this.$route.name === 'contractChangeError') return;

    if (this.$route.name !== this.getCurrentStep()) {
      this.$router.push({ name: this.getCurrentStep(), query: this.$route.query });
    }
  },
  destroyed() {
    if (this.websocketClient) {
      this.websocketClient.disconnect();
    }
  },
  methods: {
    ...mapActions([
      'validateQuote',
      'completeCustomerPaymentInfos',
      'fetchQuote',
      'createChargebeeSource',
    ]),
    ...mapMutations(['setProvisioningAction']),
    redirectToErrorPage(errorType) {
      this.$router.push({ name: 'error', query: this.$route.query, params: { error: errorType } });
    },
    sidebarImgLink(step) {
      return this.sidebarAssetsPath()[step];
    },
    sidebarAssetsPath() {
      return {
        CreateShop: new URL('@assets/sidebar-images/CreateShop.svg', import.meta.url).href,
      };
    },
    handleDisableSubmit() {
      this.isSubmitDisabled = true;
    },
    handleEnableSubmit() {
      this.isSubmitDisabled = false;
    },
    submitStep() {
      this.isStepLoading = true;
      this.currentStep.submitAction()
        .then(() => {
          this.handleEnableSubmit();
          if (this.getCurrentStep() !== this.$route.name) {
            this.$router.push({
              name: this.getCurrentStep(),
              query: this.$route.query,
            });
          }
        })
        .catch(error => {
          let message = this.$t('common.error_message');
          if (error?.response?.data?.message === 'Email already taken') {
            message = this.$t('step_error.email_error', { email: this.quote.organisation.contacts[0].email });
          }
          this.makeAlertToast(message);
        });
    },
    getCurrentStep() {
      if (this.quote.status !== 'Sent' && this.quote.status !== 'Presented') return 'contractChangeError';
      if (this.isStepLoading) return 'createShopLoader';
      return 'createShop';
    },
    async submitProvisioning() {
      this.isStepLoading = true;

      this.$router.push({
        name: 'createShopLoader',
        query: this.$route.query,
      });

      if (this.displayPaymentByCreditCard) {
        // send billing info to Chargebee
        await billingAutomationClient
          .createChargebeeSource(
            this.shopsPaymentInfos[0].paymentOwnerChargebeeId,
            this.shopsPaymentInfos[0].billingAddress.city,
            this.shopsPaymentInfos[0].billingAddress.country_code,
            this.shopsPaymentInfos[0].billingAddress.email,
            this.shopsPaymentInfos[0].billingAddress.first_name,
            '', // iban
            this.shopsPaymentInfos[0].billingAddress.last_name,
            this.shopsPaymentInfos[0].billingAddress.line1,
            this.shopsPaymentInfos[0].billingAddress.zip,
          );

        // update quote to Raul
        await this.completeCustomerPaymentInfos(this.shopsPaymentInfos[0]);
      } else {
        // send billing info to Chargebee and update quote to Raul
        await this.createChargebeeSource(this.shopsPaymentInfos[0]);
      }

      await billingAutomationClient
        .updateOrganisation({
          quoteId: this.quote.id,
          token: this.$route.query.t,
          sendEmail: true,
          parentClusterNodeId: this.$route.query.c,
        });

      this.websocketClient = new WebsocketClient({ type: 'Onboarding', uuid: btoa(this.quote.id) });
      this.websocketClient.connect(async event => {
        if (event.data === 'pingToQuoteToRedirect') {
          // need to refresh the quote so that we have the id of the new shop
          await this.fetchQuote(this.quoteId);

          this.redirectUserToSkello();
        } else {
          console.error('Websocket error: unknow message', { event });
        }
      });
      setTimeout(() => {
        this.redirectUserToSkello();
      }, 60000);
    },
    redirectUserToSkello() {
      if (this.$route.name === 'error' || this.$route.name === 'contractChangeError') return;

      // to be doubly sure - sometimes we have "o.<shopId>" and "<shopId>"
      const shopId = this.quote.organisation.shops[0].SK_shop_id && this.quote.organisation.shops[0].SK_shop_id.includes('.') ?
        this.quote.organisation.shops[0].SK_shop_id.split('.')[1] :
        this.quote.organisation.shops[0].SK_shop_id;

      // In the context of contract change, user should already be logged to skello
      window.location.href = `${VUE_APP_SKELLO_API_URL}/v3/shops/${shopId}/admin-onboarding?needs_jwt=true`;
    },
  },
};
</script>

<style lang="scss">
// Application-wide css
* {
  box-sizing: border-box;

  // Recenters the tick in the checkbox
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

#toast-portal {
  position: relative;
  z-index: 101;

  +#cb-container {
    // Chargebee set their modal z-index at the highest possible value (2'147'483'647)
    // We need our error toasters over it (and in general, it's not great practice)
    // so we set it to a high, but more manageable value
    z-index: 100 !important;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 25vh;
  width: 100%;
}

.spin-loading {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: .25rem solid rgba(0, 186, 218, .2);
  border-top-color: #00bada;
  animation: spin 1s infinite linear;
}

.spin-loading.loading--double {
  border-style: dotted;
  border-width: 5px;
}

</style>
